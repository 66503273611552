import { call, host } from "../services/api";

export const getAllBlogs = async () => {
  const response = await call("get", "api/blogs");
  const data = response.data.blogs;

  const transformedData = data.map((data) => {
    const photoUrl = `${host}/api/images/${data.photo}`;
    return {
      ...data,
      key: data.id,
      photoUrl: photoUrl
    }
  });

  return transformedData;
}

export const getFeaturedBlogs = async () => {
  const blogs = await getAllBlogs();

  const featuredBlogs = blogs.filter((blog, index) => (index > (blogs.length - 3)) ? blog : null);
  return featuredBlogs;

}

export const getBlog = async (id) => {
  const response = await call("get", `api/blogs/${id}`);
  const blog = response.data.blog;
  const relatedBlogs = response.data.relatedBlogs;
  const photoUrl = `${host}/api/images/${blog.photo}`;

  const transformedData = {
    blog: {
      ...blog,
      photoUrl: photoUrl
    },
    relatedBlogs: relatedBlogs.map((blog)=> {
      return {
        ...blog,
        photoUrl: `${host}/api/images/${blog.photo}`
      }
    })
  }
  return transformedData;
}



export const getAllPhotos = async () => {
  const response = await call("get", "api/gallery");
  const data = response.data.gallery;

  const transformData = data.map((data) => {
    const photoUrl = `${host}/api/images/${data.photo}`;
    return {
      ...data,
      key: data.id,
      photoUrl: photoUrl
    }
  });

  return transformData;
}
export const getFeaturedPhotos = async () => {
  const photos = await getAllPhotos();
  const featuredPhotos = photos.filter((data, index) => (index > (photos.length - 5)) ? data : null);
  return featuredPhotos;
}


export const getAllVideos = async () => {
  const response = await call("get", "api/videos");
  const data = response.data.videos;

  const transformedData = data.map((data) => {
    const videoUrl = `${host}/api/upload_video/${data.video}`;
    return {
      ...data,
      key: data.id,
      videoUrl: videoUrl
    }
  })

  return transformedData;

}
export const getFeaturedVideos = async () => {
  const videos = await getAllVideos();

  const featuredVideos = videos.filter((data, index) => (index > (videos.length - 5)) ? data : null);;
  return featuredVideos;
}

const COREVALUES = [
  {
    id: 1,
    icon: `${window.location.origin}/images/accountability.png`,
    title: 'တာဝန်ယူမှု(Accountability)',
    description: 'အမြတ်အစွန်းမယူသော လူမှုရေးအဖွဲ့အစည်းတစ်ခုဖြစ်သည့်အလျောက် စေတနာရှင်၊ အလှူရှင် တို့၏ ယုံကြည်စိတ်ချစွာအပ်နှံထားသော အလှူငွေအရပ်ရပ်ကို အကြီးမြတ်ဆုံးတန်ဖိုးထားကာ စံနမူနာကောင်းဖြစ်အောင် တာဝန်ယူလုပ်ဆောင်သွားမည်။',
  },
  {
    id: 2,
    icon: `${window.location.origin}/images/respect.png`,
    title: 'လေးစားမှု (Respect)',
    description: 'ကျွန်ုပ်တို့သည် ထိတွေ့ဆက်ဆံနေရသောသူအားလုံးနှင့်ဆက်ဆံရာတွင် လေးစားမှုသည် အခြေခံမူတစ်ရပ်ဖြစ်သည်။',
  },
  {
    id: 3,
    icon: `${window.location.origin}/images/entrepreneurialship.png`,
    title: 'စွန့်ဦးတီထွင်မှု (Entrepreneurial ship)',
    description: 'ကျွန်ုပ်တို့သည် တီထွင်ဆန်းသစ်မှုကို တန်ဖိုးထားပြီး အဖွဲ့အစည်း၏ပဋိညာဉ်စာတမ်းပါ ရည်မှန်းချက်များ အစွမ်းကုန်ပြည့်မီစေရန် တွေ့ကြုံရင်ဆိုင်ရသည့် အခက်အခဲများကို အခွင့်အလမ်းများအဖြစ်ပြောင်းလဲသည်။',
  },
  {
    id: 4,
    icon: `${window.location.origin}/images/collaboration.png`,
    title: 'ပူးပေါင်းဆောင်ရွက်မှု (Collaboration',
    description: 'ကျွန်ုပ်တို့သည် ပဋိညာဉ်စာတမ်းပါရည်မှန်းချက်များ အောင်မြင်စေရန်အတွက် ရည်မှန်းချက်တူညီ သောအခြားသောအဖွဲ့အစည်းများနှင့် လက်တွဲပူးပေါင်းဆောင်ရွက်ပြီး အသင်းအဖွဲ့စိတ်ဓါတ်ဖြင့် ဆက်ဆံရေးနှင့်ပူးပေါင်းပါဝင်ရေးကို အလေးထားအားပေးသည်။',
  },

];
export function getCoreValues() {
  return COREVALUES;
}

