import { useEffect } from "react";
import { useState } from "react";
import Blogs from "../components/Blogs/Blogs";
import { getAllBlogs } from "../data/data";

const BlogsPage = () => {
    const [blogs, setBlogs] = useState([]);
    useEffect(()=>{
      const fetchData = async()=>{
        const blogs = await getAllBlogs();
        setBlogs(blogs);
      }
      fetchData();
      return ()=> fetchData();
    }, [])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      };
    scrollToTop();
    return ( 
        <Blogs blogs={blogs}/>
     );
}
 
export default BlogsPage;