import { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import GalleryMain from "../components/Gallery/GalleryMain";
import { getAllPhotos, getAllVideos } from "../data/data";

const GalleryPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
scrollToTop();


   const [photos, setPhotos] = useState([]);
   const[videos, setVideos] = useState([]);
   useEffect(()=>{
    const fetchData = async ()=>{
      const photoData = await getAllPhotos();
      setPhotos(photoData);
      const videoData = await getAllVideos();
      setVideos(videoData);
    }
    fetchData();
    return ()=>fetchData();
   },[])
    return ( 
      <Fragment>
        <GalleryMain photos={photos} videos={videos}/>
      </Fragment>
     );
}
export default GalleryPage;