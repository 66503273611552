import { getReadableDateDisplay } from '../../utils/convertToHumanReadableTime';
import styles from './Blog.module.css';

const Blog= ({ blog }) => {
    return ( 
        <div className={styles.container}>
           <img src={blog?.photoUrl} alt={blog?.title} className={styles.img} />
           <div className={styles.textcontainer}>
                <p className={styles.title}>{blog?.title}</p>
                <p className={styles.postingdate}>{getReadableDateDisplay(blog?.created_at)}</p>
                <p className={styles.description}><span dangerouslySetInnerHTML={{ __html: blog?.description }} /></p>
            </div>
        </div>
     );
}
 
export default Blog;